import React, {useEffect, useLayoutEffect, useState} from 'react';
import styled from "styled-components";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import AdminLayout from "./components/AdminLayout";
import History from "./utils/history";
import axios from "axios";
import AuthPage from "./pages/Admin/AuthPage";
import HomePage from "./pages/Admin/HomePage";
import UsersPage from "./pages/Admin/UsersPage";
import FeedbackPage from "./pages/Admin/FeedbackPage";
import CatalogManagementPage from "./pages/Admin/CatalogManagementPage";
import MediaPage from "./pages/Admin/MediaPage";
import CatalogPage from "./pages/Common/CatalogPage";
import AdminCatalogPage from "./pages/Admin/CatalogPage";
import CommonLayout from "./components/CommonLayout";
import AboutPage from "./pages/Common/AboutPage";
import PublicOfferAgreementPage from "./pages/Common/PublicOfferAgreementPage";
import PrivacyPolicyPage from "./pages/Common/PrivacyPolicyPage";
import ProcessingPersonalDataPage from "./pages/Common/ProcessingPersonalDataPage";
import CreateProfilePage from "./pages/Common/CreateProfilePage";
import LoginPage from "./pages/Common/LoginPage";
import RestorePasswordPage from "./pages/Common/RestorePasswordPage";
import FavoritesPage from "./pages/Common/FavoritesPage";
import store from "./redux/store";
import {refreshTokenAction} from "./redux/actions/loginActions";
import {useSelector} from "react-redux";
import ProductPage from "./pages/Common/ProductPage";
import CartPage from "./pages/Common/CartPage";
import OrdersPage from "./pages/Admin/OrdersPage";
import CommonMediaPage from "./pages/Common/CommonMediaPage";
import ProfilePage from "./pages/Common/ProfilePage";
import MainPage from "./pages/Common/MainPage";
import {RoleType, UserType} from "./utils/types";
import MyOrders from "./pages/Common/MyOrders";
import LocationPage from "./pages/Common/LocationPage";
import {initLocalStorageAction} from "./redux/actions/pageActions";
import VerifyPage from "./pages/Common/VerifyPage";
import ConfirmEmailPage from "./pages/Common/ConfirmEmailPage";
import SettingDasboardPage from "./pages/Admin/SettingDasboardPage";
import SettingsPage from "./pages/Admin/SettingsPage";
import RepairPage from "./pages/Common/RepairPage";

const StyledApp = styled.div`
    display: flex;
    width: 100%;
    //overflow: hidden;
    justify-content: center;
`;

function useWindowSize() {
    const [size, setSize] = useState();
    useLayoutEffect(() => {
        function updateSize() {
            // @ts-ignore
            setSize(window.innerWidth);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


function App() {
    const location = useLocation();

    const width = useWindowSize();

    History.navigate = useNavigate();
    History.location = location;
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);

    const loading = useSelector((state: any) => state.loginReducer.loading)

    useEffect(() => {
        if (location.pathname !== "/profile/fill")
            if (user.Role !== RoleType.admin && (user.Name === "" || user.Email === ""))
                History.push("/profile/fill")

        // window.document.querySelector("#root")!.scrollIntoView({block: "start"});
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        store.dispatch(refreshTokenAction());
        store.dispatch(initLocalStorageAction());
        axios.interceptors.response.use(response => response, error => {
            const statusCode = error.response ? error.response.status : null;
            if (statusCode === 401) {
                History.push("/profile/login");
            }
            throw error;
        })
    }, [])
    return (
        <StyledApp>
            <Routes>
                {!loading ? <React.Fragment>
                    <Route path={"/orders"} element={
                        <CommonLayout>
                            <MyOrders/>
                        </CommonLayout>}/>
                    <Route path={"/profile"} element={
                        <CommonLayout>
                            <ProfilePage/>
                        </CommonLayout>}/>
                    <Route path={"/profile/fill"} element={
                        <CommonLayout>
                            <ProfilePage fill/>
                        </CommonLayout>}/>
                    <Route path={"/admin/home"} element={
                        <AdminLayout>
                            <HomePage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/users"} element={
                        <AdminLayout>
                            <UsersPage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/feedback"} element={
                        <AdminLayout>
                            <FeedbackPage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/catalog"} element={
                        <AdminLayout>
                            <AdminCatalogPage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/catalog/management"} element={
                        <AdminLayout>
                            <CatalogManagementPage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/media"} element={
                        <AdminLayout>
                            <MediaPage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/orders"} element={
                        <AdminLayout>
                            <OrdersPage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/menu/settings"} element={
                        <AdminLayout>
                            <SettingDasboardPage/>
                        </AdminLayout>}/>
                    <Route path={"/admin/settings"} element={
                        <AdminLayout>
                            <SettingsPage/>
                        </AdminLayout>}/>
                    <Route path={"/profile/confirm"} element={
                        <CommonLayout>
                            <CreateProfilePage code/>
                        </CommonLayout>}/>
                </React.Fragment> : <Route path={"*"} element={
                    <>
                        Загрузка
                    </>
                }>
                </Route>}
                <Route path={"/"} element={
                    <CommonLayout>
                        <MainPage/>
                    </CommonLayout>}/>
                <Route path={"/doc/personal"} element={
                    <CommonLayout>
                        <ProcessingPersonalDataPage/>
                    </CommonLayout>}/>
                <Route path={"/doc/public"} element={
                    <CommonLayout>
                        <PublicOfferAgreementPage/>
                    </CommonLayout>}/>
                <Route path={"/doc/policy"} element={
                    <CommonLayout>
                        <PrivacyPolicyPage/>
                    </CommonLayout>}/>
                <Route path={"/about"} element={
                    <CommonLayout>
                        <AboutPage/>
                    </CommonLayout>}/>
                <Route path={"/media"} element={
                    <CommonLayout>
                        <CommonMediaPage/>
                    </CommonLayout>}/>
                <Route path={"/catalog"} element={
                    <CommonLayout>
                        <CatalogPage/>
                    </CommonLayout>}/>
                <Route path={"/repair"} element={
                    <CommonLayout>
                        <RepairPage/>
                    </CommonLayout>}/>
                <Route path={"/favorites"} element={
                    <CommonLayout>
                        <FavoritesPage/>
                    </CommonLayout>}/>
                <Route path={"/location"} element={
                    <CommonLayout>
                        <LocationPage/>
                    </CommonLayout>}/>
                <Route path={"/catalog/:id"} element={
                    <CommonLayout>
                        <ProductPage/>
                    </CommonLayout>}/>
                <Route path={"/admin"} element={
                    <AdminLayout>
                        <AuthPage/>
                    </AdminLayout>}/>
                <Route path={"/profile/login"} element={
                    <CommonLayout>
                        <LoginPage/>
                    </CommonLayout>}/>
                <Route path={"/profile/create"} element={
                    <CommonLayout>
                        <CreateProfilePage/>
                    </CommonLayout>}/>
                <Route path={"/profile/confirm/email/:email"} element={
                    <CommonLayout>
                        <ConfirmEmailPage/>
                    </CommonLayout>}/>
                <Route path={"/cart"} element={
                    <CommonLayout>
                        <CartPage/>
                    </CommonLayout>}/>
                <Route path={"/profile/restore"} element={
                    <CommonLayout>
                        <RestorePasswordPage/>
                    </CommonLayout>}/>
                <Route path={"/profile/verify/:phone"} element={
                    <CommonLayout>
                        <VerifyPage/>
                    </CommonLayout>}/>
            </Routes>
        </StyledApp>
    );
}

export default App;
