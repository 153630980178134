import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import LogoSVG from "../icons/LogoSVG";
import {Badge, Button, Collapse, Input, Menu, MenuProps, Popover, Space} from "antd";
import LoginSVG from "../icons/LoginSVG";
import StarSVG from "../icons/StarSVG";
import CartSVG from "../icons/CartSVG";
import BalloonSVG from "../icons/BalloonSVG";
import {Link, useLocation} from "react-router-dom";
import PhoneSVG from "../icons/PhoneSVG";
import BurgerSVG from "../icons/BurgerSVG";
import ZoomSVG from "../icons/ZoomSVG";
import ArrowDownSVG from "../icons/ArrowDownSVG";
import {CartType, GroupType, MenuItemType, ProductType, UserType} from "../utils/types";
import {useSelector} from "react-redux";
import ProfileSVG from "../icons/ProfileSVG";
import store from "../redux/store";
import {
    changeCatalogOpenAction,
    changeFeedbackOpenAction,
    getGroupsAction,
    searchAction
} from "../redux/actions/pageActions";
import ArrowRectangleSVG from "../icons/ArrowRectangleSVG";
import ArrowSVG from "../icons/ArrowSVG";
import Arrow45SVG from "../icons/Arrow45SVG";
import History from "../utils/history";
import SearchArrowSVG from "../icons/SearchArrowSVG";
import SadSmileSVG from "../icons/SadSmileSVG";
import MailSVG from "../icons/MailSVG";
import Logo2SVG from "../icons/Logo2SVG";
import Logo3SVG from "../icons/Logo3SVG";
import {useMediaQuery} from "usehooks-ts";
import TelegramSVG from "../icons/TelegramSVG";

const {Panel} = Collapse;

const StyledCommonHeader = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 18.65%;
  padding-right: 18.65%;

  .logo2, .logo3 {
    display: none;
  }

  @media screen and (max-width: 1919px) {
    padding-left: 2%;
    padding-right: 2%;
  }
  @media screen and (max-width: 999px) {
    .logo1 {
      display: none;
    }

    .logo2 {
      display: flex;
    }
  }

  @media screen and (max-width: 479px) {

  }

  a {
    display: flex;
    text-decoration: none;
    color: #000;
  }

  .ant-input-affix-wrapper {
    margin-right: 40px;
    padding: 15px 18px 15px 24px;
    border: 1px solid #D7D8D3;
    border-radius: 4px;

    input {
      color: #8A8A8A;
      line-height: 25px;
      font-weight: 400;
      font-size: 18px;
    }
  }

  .ant-btn {
    background: #5B77CB;
    border-radius: 4px;
    margin-right: 21px;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    border: none;
    align-items: center;
    display: flex;
    color: #FFFFFF !important;
    height: 57px;
    padding: 21px 26px;

    svg {
      margin-right: 12px;
    }
  }

  .logo {
  }

  .ant-menu-vertical {
    box-shadow: none !important;
  }

  .ant-menu {
    border-radius: 4px;

    .ant-menu-submenu-title {
      margin: 0 !important;
      width: 100%;
      margin-block: 0;
      margin-inline: 0;
      border-radius: 0;
    }
  }

  //.ant-collapse-content-box, .ant-collapse-header {
  //  padding: 0 !important;
  //  padding-block: 0 !important;
  //}

  .phonePop {
    width: max-content;
    right: 20px;

    .ant-space {
      & > :first-child {
        display: none;
      }
    }

    @media screen and (max-width: 999px) {
      .ant-space {
        & > :first-child {
          display: flex;
        }
      }
    }

    .ant-popover-inner {
      background: #FFFFFF;
      padding: 18px;
      border: 1px solid #D7D8D3;
      border-radius: 4px;
      box-shadow: none !important;
    }
  }

  .searchInput {
    z-index: 999 !important;
  }

  .searchNothing {
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    padding: 50px;
    font-size: 18px;
    flex-direction: column;
    color: #8a8a8a;

    svg {
      margin-top: 10px;
    }
  }

  .searchPop {
    width: 100%;
    z-index: 9999;

    .ant-popover-inner {
      border-radius: 4px;
      border: 1px solid #D7D8D3;
      background: #FFFFFF;
      padding: 0;
    }
  }

  .menuPop {
    //  left: 50% !important;
    //  transform: translateX(-50%) !important;
    //  width: 80% !important;
    //
    //  .ant-popover-arrow {
    //    display: none;
    //  }
    //
    .ant-popover-inner {
      background: #FFFFFF;
      //border: 1px solid #EBEBEB;
      border-radius: 4px;
      padding: 0;

      .ant-popover-inner-content {
        border-radius: 4px;
      }
    }
  }

  .ant-badge {
    sup {
      padding: 1px;
      box-shadow: none;
    }
  }

  svg {
    flex: 0 0 auto;
  }
`;
const CatalogButton = styled(Button)`
  @media screen and (max-width: 1919px) {
    &.ant-btn {
      padding: 20px 17px;

      svg {
        margin: 0;
      }

      span {
        display: none;
      }
    }
  }
`;

const LocalMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 30px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;

    /* black */

    color: #311C1C;

    &:hover {
      color: #5a7ac7;
    }
  }
`;

const MenuSubItem = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  color: #404040;

  &:hover {
    color: #5a7ac7;
  }

  &.more {
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
    color: #8C8C8C;
    line-height: 140%;

    svg {
      margin-left: 6px;
    }
  }
`;

const Center = styled.div`
  display: flex;
  margin-bottom: 34px;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;

  .logo {
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 999px) {
    .logo {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
    }

    > a:nth-child(1) {
      order: 1;
      margin-left: auto;
    }

    > span {
      order: 2;
      margin-left: 34px;
    }

    a:nth-child(2) {
      order: 0;
    }
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;

  color: #000000;


  .ant-badge {
    display: flex;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000000;
  }

  &:not(:last-child) {
    margin-right: 50px;
  }

  svg {
    margin-bottom: 4px;
  }

  @media screen and (max-width: 1919px) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 999px) {
    justify-content: unset;
    align-self: unset;
    a {
      span {
        display: none;
      }
    }
  }
`;

const RightPart = styled.div`
  display: flex;
  margin-left: auto;
  gap: 45px;

  a {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 25.2px */
    text-decoration-line: underline;

    &:first-child {
      color: #5b77cb;
    }

    svg {
      margin-right: 15px;
    }

    &:first-child {
      svg {
        margin-right: 10px;
      }
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  @media screen and (max-width: 999px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-decoration: none;

    &.call {
        text-decoration-line: underline;

        color: #5B77CB;
    }

    &:not(:last-child) {
        margin-right: 40px;
    }

    color: #000000;
`;

const SearchItem = styled(Link)`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  padding: 15px 25px 15px 25px;
  color: #000000 !important;
  cursor: pointer;

  span {
    margin-right: 15px;
  }

  svg {
    &:first-child {
      margin-right: 15px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &:hover {
    background: #DEE4F5;

    svg {
      &:first-child {
        path {
          stroke: #000;
        }
      }

      &:last-child {
      }
    }
  }
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;

  svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 999px) {
    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
`;

const Avatar = styled.img`
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
  object-fit: cover;
  border-radius: 100%;
`;

const Phone = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-left: auto;

  color: #000000;

  &:hover {
    color: #5b77cb;
  }

  svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 999px) {
    span {
      display: none;
    }
  }
`;

const PhoneWrap = styled(Popover)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-left: auto;
  cursor: pointer;
  gap: 10px;
  color: #5B77CB;


  & > a {
    text-decoration: underline;
    color: #5B77CB;
  }

  svg {
    &:first-child {
    }

    &.arrow {
      scale: 1.3;

      path {
        stroke: #5B77CB;
      }
    }
  }

  @media screen and (max-width: 999px) {
    gap: 0;
  }
`;

function NewCommonHeader() {
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);
    const cart: CartType = useSelector((state: any) => state.pageReducer.cart);
    const groups: GroupType[] = useSelector((state: any) => state.pageReducer.groups);
    const localFavorites: number[] = useSelector((state: any) => state.pageReducer.localFavorites);
    const localCart: number[] = useSelector((state: any) => state.pageReducer.localCart);
    const searchResult: ProductType[] = useSelector((state: any) => state.pageReducer.searchResult)

    const mobileSize = useMediaQuery('(max-width: 999px)')

    const [searchValue, setSearchValue] = useState<string>("");
    const [searchOpened, setSearchOpened] = useState<boolean>(false);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [phoneOpened, setPhoneOpened] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
        store.dispatch(getGroupsAction());
    }, [])

    useEffect(() => {
        setMenuOpen(false);
        setSearchOpened(false);
    }, [location])

    useEffect(() => {
        const controller = new AbortController();
        if (searchValue.trim() !== "") {
            setSearchOpened(false);
            store.dispatch(searchAction(searchValue, controller.signal)).then(() => {
                setSearchOpened(true)
            })
        } else {
            setSearchOpened(false);
        }
    }, [searchValue])

    const getItem = (
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItemType[],
        type?: 'group',
    ): MenuItemType => {
        return {
            popupOffset: [0, 0],
            style: {
                width: mobileSize ? 350 : 375
            },
            key,
            icon,
            children,
            label,
            type,
            onTitleClick: () => {
                History.push(`/catalog?group=${key}`)
            },
            onClick: () => {
                !children && History.push(`/catalog?group=${key}`)
            }
        } as MenuItemType;
    }

    // const items: MenuItem[] = [
    //     getItem('Navigation One', 'sub1', null, [
    //         getItem('Item 1', null, null, [getItem('Option 1', '1'), getItem('Option 2', '2')], 'group'),
    //         getItem('Item 2', null, null, [getItem('Option 3', '3'), getItem('Option 4', '4')], 'group'),
    //     ]),
    //
    //     getItem('Navigation Two', 'sub2', null, [
    //         getItem('Option 5', '5'),
    //         getItem('Option 6', '6'),
    //         getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
    //     ]),
    //
    //     getItem('Navigation Three', 'sub4', null, [
    //         getItem('Option 9', '9'),
    //         getItem('Option 10', '10'),
    //         getItem('Option 11', '11'),
    //         getItem('Option 12', '12'),
    //     ]),
    // ];

    const menuItems: MenuItemType[] = groups.map((group) => getItem(group.Name, group.Id, null, group.ChildGroups?.map((sub_group) =>
        getItem(sub_group.Name, sub_group.Id, null, sub_group.ChildGroups?.map((sub_sub_group) =>
            getItem(sub_sub_group.Name, sub_sub_group.Id, null)
        ) || undefined)
    )));

    const mobileSize550 = useMediaQuery('(max-width: 550px)')

    const showCatalog = useSelector((state: any) => state.pageReducer.showCatalog)

    return (
        <StyledCommonHeader>
            <Top>
                {mobileSize ? <a target={"_blank noreferrer noopener"} href="https://t.me/gzaryazavod">
                    <Location>
                        <TelegramSVG/>
                    </Location>
                </a> : <Link to={"/location"}>
                    <Location>
                        <BalloonSVG/>
                        <span>г. Челябинск</span>
                    </Location>
                </Link>}
                <Link to={"/"}>
                    <LogoSVG className={"logo logo1"}/>
                    <Logo2SVG className={"logo logo2"}/>
                    <Logo3SVG className={"logo logo3"}/>
                </Link>
                <PhoneWrap open={phoneOpened} overlayClassName={"phonePop"}
                           getPopupContainer={() => document.querySelector(`.${StyledCommonHeader.styledComponentId}`)!}
                           arrow={false} placement={"bottom"} content={
                    <Space direction={"vertical"} align={"center"}>
                        <Phone href={"tel:+79227333371"}><PhoneSVG/> 8 922 73 33 371</Phone>
                        <StyledLink style={{fontWeight: 500}} to={"/"} onClick={() => {
                            store.dispatch(changeFeedbackOpenAction(true))
                            setPhoneOpened(false)
                        }} className={"call"}>
                            Обратный звонок
                        </StyledLink>
                    </Space>}>
                    <Phone href={"tel:+79227333371"}><PhoneSVG/><span> 8 922 73 33 371</span></Phone> <ArrowDownSVG
                    className={"arrow"} onClick={() => setPhoneOpened(phoneOpened => !phoneOpened)}
                    style={{transform: `rotate(${phoneOpened ? 180 : 0}deg)`}}/>
                </PhoneWrap>
            </Top>
            <Center>
                {(!mobileSize550 || !searchOpened) && <>
                    {mobileSize ? <CatalogButton onClick={() => {
                        if (!showCatalog) {
                            store.dispatch(changeCatalogOpenAction(true))
                            History.push("/catalog")
                        } else {
                            store.dispatch(changeCatalogOpenAction(false))
                        }
                    }}>
                        <BurgerSVG/><span>Каталог</span>
                    </CatalogButton> : <Popover
                        open={menuOpen}
                        arrow={false}
                        overlayClassName={"menuPop"}
                        onOpenChange={(value) => setMenuOpen(value)}
                        getPopupContainer={() => document.querySelector(`.${StyledCommonHeader.styledComponentId}`)!}
                        content={
                            <Menu
                                expandIcon={<ArrowDownSVG style={{transform: "rotate(-90deg)"}}/>} mode="vertical"
                                items={menuItems}/>

                            // <LocalMenu>
                            //     {groups?.map(parentGroup => <MenuItem key={parentGroup.Id}>
                            //         <Link to={`/catalog?group=${parentGroup.Id}`}>
                            //             <h1>
                            //                 {parentGroup.Name}
                            //             </h1>
                            //         </Link>
                            //         {parentGroup.ChildGroups?.slice(0, 6)?.map(childGroup => <Link key={childGroup.Id}
                            //                                                                        to={`/catalog?group=${childGroup.Id}`}><MenuSubItem
                            //         >
                            //             {childGroup.Name}
                            //         </MenuSubItem></Link>)}
                            //         {parentGroup?.ChildGroups?.length && parentGroup?.ChildGroups?.length > 6 &&
                            //             <Collapse ghost>
                            //                 <Panel showArrow={false} key={'1'} header={<MenuSubItem className={"more"}>
                            //                     Еще <ArrowDownSVG/>
                            //                 </MenuSubItem>}>
                            //                     {parentGroup.ChildGroups?.slice(6)?.map(childGroup => <Link
                            //                         key={childGroup.Id}
                            //                         to={`/catalog?group=${childGroup.Id}`}>
                            //                         <MenuSubItem>
                            //                             {childGroup.Name}
                            //                         </MenuSubItem>
                            //                     </Link>)}
                            //                 </Panel>
                            //             </Collapse>}
                            //     </MenuItem>)}
                            // </LocalMenu>
                        } trigger={['click']}>
                        <CatalogButton>
                            <BurgerSVG/><span>Каталог</span>
                        </CatalogButton>
                    </Popover>}
                </>
                }
                <Popover
                    open={searchOpened}
                    arrow={false}
                    placement={"bottom"}
                    onOpenChange={value => setSearchOpened(value)}
                    overlayClassName={"searchPop"}
                    trigger={['click']}
                    content={searchResult.length > 0 ? searchResult?.map(el => <SearchItem to={`/catalog/${el.Id}`}
                                                                                           key={el.Id}>
                        <ZoomSVG/>
                        <span>{el.Name}</span>
                        <SearchArrowSVG/>
                    </SearchItem>) : <span className={"searchNothing"}>
                        Пусто... <SadSmileSVG/>
                    </span>}
                    getPopupContainer={() => document.querySelector(`.searchInput`)!}>
                    <Input
                        style={!(!mobileSize550 || !searchOpened) ? {margin: 0}:{}}
                        className={"searchInput"}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={mobileSize550 ? "" : "Поиск по сайту"} suffix={<ZoomSVG/>}/>
                </Popover>
                {(!mobileSize550 || !searchOpened) && <>
                    {user?.Login ? <Action style={{alignSelf: "auto"}}><Link
                            to={"/profile"}>{user.PhotoUrl?.replace("//", "/") !== "" ?
                            <Avatar src={`/${user.PhotoUrl?.replace("//", "/")}`}/> : <ProfileSVG/>}</Link></Action> :
                        <Action>
                            <Link to={"/profile/login"}><LoginSVG/><span>Войти</span></Link>
                        </Action>}
                    <Action><Link to={"/favorites"}>
                        <Badge offset={[mobileSize ? 0 : 2, 8]}
                               size={mobileSize ? "small" : "default"}
                               count={(user.Login ? user?.Meta?.Favorites?.length : localFavorites?.length) || 0}
                               color={"#5B77CB"}><StarSVG/></Badge><span>Избранное</span></Link></Action>
                    <Action><Link to={"/cart"}>
                        <Badge offset={[mobileSize ? 0 : 2, 8]}
                               size={mobileSize ? "small" : "default"}
                               count={(user.Login ? cart?.Positions?.length : localCart?.length) || 0}
                               color={"#5B77CB"}><CartSVG/></Badge><span>Корзина</span></Link></Action>
                </>}
            </Center>
            <Bottom>
                <StyledLink to={"/about"}>
                    О нас
                </StyledLink>
                <StyledLink to={"/media"}>
                    Медиатека
                </StyledLink>
                <StyledLink to={"/repair"}>
                    Сервис
                </StyledLink>
                <StyledLink to={"#"} onClick={() => {
                    store.dispatch(changeFeedbackOpenAction(true))
                }}>
                    Напишите нам
                </StyledLink>
                <RightPart>
                    <a target={"_blank noreferrer noopener"} href="https://t.me/zaryazavod"><TelegramSVG/> Мы в
                        Телеграмм</a>
                    <a href="mailto:zarya.zav@yandex.ru"><MailSVG/>zarya.zav@yandex.ru</a>
                </RightPart>
            </Bottom>
        </StyledCommonHeader>
    );
}

export default NewCommonHeader;