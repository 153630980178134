import React from 'react';
import styled from "styled-components";
import {Button} from "antd";
import secondaryBanner from "../images/secondaryBanner.png"
import {Link} from "react-router-dom";
import {GroupType} from "../utils/types";

const StyledSecondaryBanner = styled.div`
  display: flex;
  width: 380px;
  height: 250px;
  position: relative;
  align-items: center;
  background: #F5F5F5;
  border-radius: 10px;
  padding: 0 20px;
  text-align: start;

  &:hover {
    background: #5B77CB;

    h1, p {
      color: #fff;
    }

    .ant-btn {
      background: #fff;

      span {
        color: #000;
      }
    }
  }

  h1 {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;

    color: #000000;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;


    color: #000000;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 200px;
  }

  img {
    display: flex;
    position: absolute;
    right: 20px;
    width: 200px;
    object-fit: contain;
  }

  .ant-btn {
    background: #5B77CB;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    transition: all 0s !important;
    height: auto;
    border: none;
    padding: 10px 22px;

    span {
      color: #FFFFFF;
    }
  }

  @media screen and (max-width: 1919px) {
    width: 380px;

    img {
      height: 60%;
    }
  }
  @media screen and (max-width: 999px) {
    p {
      width: 200px;
    }

    width: 380px;
  }

  @media screen and (max-width: 479px) {
    width: 320px;
    flex-direction: column-reverse;
    height: 408px;
    align-items: center;
    justify-content: center;

    img {
      right: 0;
      position: relative;
      height: 180px;
    }
  }
`;

type SecondaryBannerType = {
    data: GroupType
}

function SecondaryBanner({data}: SecondaryBannerType) {
    return (
        <StyledSecondaryBanner>
            <span className="left">
                <h1>{data.Name}</h1>
                <p>{data.FullDesc}</p>
                <Link to={`/catalog?group=${data.Id}`}>
                    <Button>
                        В каталог
                    </Button>
                </Link>
            </span>
            {data.ImageUrl !== "" && <img src={data.ImageUrl} alt=""/>}
        </StyledSecondaryBanner>
    );
}

export default SecondaryBanner;