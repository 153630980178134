import React from 'react';
import styled from "styled-components";
import repairIMG from "../../images/repair.png"
import repairIMG2 from "../../images/repair2.png"
import {Space} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";
import store from "../../redux/store";
import {changeFeedbackOpenAction} from "../../redux/actions/pageActions";

const Blocks = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 105px;
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #DCDCDC;
    padding: 46px 46px 38px 46px;
    width: 100%;
    max-width: 380px;

    h3 {
        margin-bottom: 50px;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
    }

    .action {
        font-size: 18px;
        line-height: 26px;
        color: #5b77cb;
        text-decoration: underline;
        cursor: pointer;
    }
`;
const RoundBlock = styled(Block)`
    border-radius: 10px;
    background: #fff;
    border: none;
    height: 210px;

    h3 {
        margin-bottom: auto;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 34px;

    h1 {
        color: #5b77cb;
        margin: 0;
        text-transform: uppercase;
        font-size: 42px;
        font-weight: 600;
        line-height: 52px;
    }

    span {
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
    }

    img {
        height: 30vw;
        max-height: 470px;
        position: relative;
        right: -7vw;
    }

    @media screen and (max-width: 999px) {
        img {
            display: none;
        }
    }
`;


const SwiperWrap = styled.div`
    background: #F2F2F4;
    padding: 65px 0 100px 18.75%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 999px) {
        padding-left: 2%;
    }
`;

const WhiteWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 65px 18.75% 135px 18.75%;
    @media screen and (max-width: 999px) {
        padding-left: 2%;
        padding-right: 2%;
    }
`;
const WhiteWrap2 = styled.div`
    display: flex;
    flex-direction: column;
    padding: 65px 18.75% 135px 4%;
    @media screen and (max-width: 999px) {
        padding-left: 2%;
        padding-right: 2%;
    }
`;

const StyledRepairPage = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    .swiperBottom {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }

    .mySecondSwiper {
        width: 100%;
        height: 250px;
        margin-bottom: 0;


        .swiper-slide {
            font-size: 18px;
            //background: #fff;
            width: 380px;
            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: flex-start;

            //@media screen and (max-width: 1919px) {
            //    width: 335px;
            //}
            //@media screen and (max-width: 999px) {
            //    width: 220px;
            //}
            //
            //@media screen and (max-width: 479px) {
            //    width: 160px;
            //    height: 204px;
            //}
        }
    }

    h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 60px;
    }
`;
const Top2 = styled(Top)`
    h2 {
        font-size: 28px;
        margin-bottom: 36px;

        span {
            color: #5B77CB;
            font-size: 28px;
            font-weight: 600;
        }
    }

    span {
        font-size: 18px;
        line-height: 27px;
    }

    img {
        height: 30vw;
        max-height: 470px;
        //right: 0;
        left: -5vw;
    }
`;

function RepairPage() {
    return (
        <StyledRepairPage>
            <WhiteWrap>
                <Top>
                    <Space size={12} direction={"vertical"}>
                        <h1>Заря</h1>
                        <span>
                        Новый инструментальный завод
                    </span>
                    </Space>
                    <img src={repairIMG} alt=""/>
                </Top>
                <h2>Наше производство <br/> оказывает услуги по ремонту:</h2>
                <Blocks>
                    <Block>
                        <h3>Линейки ШД ШП ШМ <br/> из стали и чугуна</h3>
                        <span className="action" onClick={() => {
                            store.dispatch(changeFeedbackOpenAction(true))
                        }}>Заказать ремонт</span>
                    </Block>
                    <Block>
                        <h3>Плиты поверочные <br/> разметочные из чугуна</h3>
                        <span className="action" onClick={() => {
                            store.dispatch(changeFeedbackOpenAction(true))
                        }}>Заказать ремонт</span>
                    </Block>
                    <Block>
                        <h3>Угольники типа УШ</h3>
                        <span className="action" onClick={() => {
                            store.dispatch(changeFeedbackOpenAction(true))
                        }}>Заказать ремонт</span>
                    </Block>
                </Blocks>
            </WhiteWrap>
            <SwiperWrap>
                <h2>Так же предоставляем услуги <br/>
                    по ремонту гранитного инструмента</h2>
                <Swiper
                    breakpoints={{
                        481: {
                            centeredSlides: false
                        },
                        0: {
                            centeredSlides: true
                        }
                    }}
                    slidesPerView={"auto"}
                    spaceBetween={16}
                    className="mySecondSwiper"
                >
                    <SwiperSlide>
                        <RoundBlock>
                            <h3>Плиты поверочные</h3>
                            <span className="action" onClick={() => {
                                store.dispatch(changeFeedbackOpenAction(true))
                            }}>Заказать ремонт</span>
                        </RoundBlock>
                    </SwiperSlide>
                    <SwiperSlide>
                        <RoundBlock>
                            <h3>Угольники</h3>
                            <span className="action" onClick={() => {
                                store.dispatch(changeFeedbackOpenAction(true))
                            }}>Заказать ремонт</span>
                        </RoundBlock>
                    </SwiperSlide>
                    <SwiperSlide>
                        <RoundBlock>
                            <h3>Линейки типа ШП ШМ</h3>
                            <span className="action" onClick={() => {
                                store.dispatch(changeFeedbackOpenAction(true))
                            }}>Заказать ремонт</span>
                        </RoundBlock>
                    </SwiperSlide>
                    <SwiperSlide>
                        <RoundBlock>
                            <h3>Ремонт призм поверочных разметочных из стали
                                и чугуна</h3>
                            <span className="action" onClick={() => {
                                store.dispatch(changeFeedbackOpenAction(true))
                            }}>Заказать ремонт</span>
                        </RoundBlock>
                    </SwiperSlide>
                </Swiper>
                <span className="swiperBottom">
                    И другого гранитного инструмента имеющего плоскостность
                </span>
            </SwiperWrap>
            <WhiteWrap2>
                <Top2>
                    <img src={repairIMG2} alt=""/>
                    <Space style={{maxWidth: 615}} size={20} direction={"vertical"}>
                        <h2>Наши производственные мощности обладают
                            оборудованием <span>шлифовки, доводки и фрезеровки</span>
                        </h2>
                        <Space size={20} direction={"vertical"}>
                    <span>
                    Наши специалисты имеют огромный опыт в работе с плоскостями имевшими допуски в МКМ
                </span>
                            <span>Наша компания обладает собственной <strong>лабораторией контроля</strong> точности инструмента, после оказания услуг по ремонту
                    вы получите документ (поверка/калибровка) государственного образца подтверждающий <strong>Класс точности ремонта</strong>
                </span>
                        </Space>
                    </Space>
                </Top2>
            </WhiteWrap2>
        </StyledRepairPage>
    );
}

export default RepairPage;